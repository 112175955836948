import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';

// material
import {
  Stack,
  TextField,
  Autocomplete,
  Divider,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Container,
  Grid,
  Box,
  Card,
  CircularProgress,
  Typography,
  Button,
  Badge,
  Modal,
  Fade,
} from '@mui/material';
import { styled } from '@mui/system';

import { Icon } from '@iconify/react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import Page from '../components/Page';
import Iconify from '../components/iconify';
import DocuwareIcon from '../components/DocuwareIcon';
import {
  getBillDetail,
  fetchQuickBooksVendors,
  createVendorMapping,
  updateBillDetails,
  fetchQuickBooksGLAccounts,
  fetchQuickBooksItems,
  updateBillLines,
  createQuickbooksVendor,
  getDocuwareDomain,
} from '../services/api';
import Breadcrumbs from '../components/Breadcrumbs';

// ----------------------------------------------------------------------

export default function BillDetail() {
  const { billId } = useParams();
  const navigate = useNavigate();
  const [fileCabinet, setFileCabinet] = useState(false);
  const [dwDocId, setDwDocId] = useState(false);
  const [configName, setConfigName] = useState(false);
  const [DWDomain, setDWDomain] = useState(false);
  const [oldApiData, setOldApiData] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [isQBVendorLoading, setIsQBVendorLoading] = useState(false);
  const [isShowQbVendor, setIsShowQbVendor] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [isItemLoading, setIsItemloading] = useState(false);
  const [isGlLoading, setIsGLLoading] = useState(false);
  const [glList, setGLList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(false);
  const [selectedDWVendor, setSelectedDWVendor] = useState(false);
  const [isLineChanged, setIsLineChanged] = useState(false);
  const [isLineAmountChanged, setIsLineAmountChanged] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isShowEdit, setIsShowEdit] = useState(false);
  const [isBillDetailsLoading, setIsBillDetailsLoading] = useState(false);
  const [billStatus, setBillStatus] = useState('');
  const [lastUpdated, setLastUpdated] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [qbBillLink, setQbBillLink] = useState('');

  const topMapping = {
    accPay: 'acc_pay',
    billNo: 'bill_no',
    vendorName: 'vendor_name',
    dwVendorName: 'docuware_vendor_name',
    isImportedInQB: 'is_imported_in_qb',
    terms: 'terms',
    billDate: 'bill_date',
    dueDate: 'due_date',
    categoryName: 'category_name',
    itemName: 'item_name',
    customerName: 'customer_name',
    description: 'description',
    quantity: 'quantity',
    rate: 'rate',
    amount: 'amount',
    locationName: 'location_name',
    className: 'class_name',
    privateNote: 'private_note',
    memo: 'memo',
  };

  const lineMapping = {
    dwLineName: '',
    itemName: '',
    categoryName: '',
    rate: 0,
    amount: '',
    quantity: 0,
    description: '',
    hasItem: false,
    hasCategory: false,
    item: false,
    category: false,
    isShowCategory: false,
    isShowItem: true,
  };

  const prepareGLList = (data) => {
    const glOptions = data
      ? data.map((item) => {
          const qbGLItem = {};
          qbGLItem.id = item.name;
          qbGLItem.label = item.name;
          return qbGLItem;
        })
      : [];
    setGLList(glOptions);
  };

  const prepareItemList = (items) => {
    const itemOptions = items
      ? items.map((item) => {
          const itemVals = {};
          itemVals.id = item.id;
          itemVals.label = item.name;
          return itemVals;
        })
      : [];
    setItemList(itemOptions);
    return itemOptions;
  };

  const prepareQBVendorList = (data) => {
    const vendorOptions = data
      ? data.map((item) => {
          const qbVendorItem = {};
          qbVendorItem.id = item.name;
          qbVendorItem.label = item.name;
          return qbVendorItem;
        })
      : [];
    setVendorList(vendorOptions);
  };

  useEffect(() => {
    if (fileCabinet && dwDocId) {
      const dwServerUrl = DWDomain || 'https://inceptiontech.docuware.cloud';
      const url = `${dwServerUrl}/DocuWare/Platform/WebClient/Integration?p=V&did=${dwDocId}&fc=${fileCabinet}`;
      setQbBillLink(url);
    }
  }, [fileCabinet, dwDocId]);

  useEffect(() => {
    const configId = localStorage.getItem('configId');
    setIsQBVendorLoading(true);
    setIsGLLoading(true);
    setIsItemloading(true);
    setIsBillDetailsLoading(true);
    if (billId) {
      getBillDetail(billId).then((response) => {
        setIsBillDetailsLoading(false);
        const apiData = response.data;
        setOldApiData(apiData);
        setLastUpdated(apiData.updated_at);
        if (apiData.is_imported_in_qb) {
          if (apiData.is_bill_paid) {
            setBillStatus('Paid');
            return;
          }
          setBillStatus('Synced');
        } else {
          setBillStatus('Not Synced');
        }
        const cacheFileCabinet = localStorage.getItem('cacheConfigFileCabinetId')
          ? localStorage.getItem('cacheConfigFileCabinetId')
          : false;
        setFileCabinet(cacheFileCabinet);
        setDwDocId(apiData.docuware_document_id);
        setFormInitialValues(apiData);
        if (apiData.vendor_name === (undefined || null) || apiData.vendor_name === 'False') {
          setIsShowQbVendor(true);
        }
        const lineItemsApiData = apiData.docuware_bill_line || [];
        if (lineItemsApiData) {
          const preparedLines = prepareLineItems(lineItemsApiData);
          setLineItems(preparedLines);
        }
      });
    } else {
      setIsBillDetailsLoading(false);
      alert('Could not get Bill Detail Please try again.');
    }

    getDocuwareDomain(configId).then((response) => {
      if (response.data && response.data.dw_server_url) {
        setDWDomain(response.data.dw_server_url);
      }
    });

    fetchQuickBooksVendors(configId).then((response) => {
      if (response.data && response.data.status_code && response.data.status_code === 401) {
        console.log('Token Expired');
      }
      if (response.data.vendor_list) {
        prepareQBVendorList(response.data.vendor_list);
        setIsQBVendorLoading(false);
      } else {
        console.log('Could not fetch Vendor From QuickBooks');
        setIsQBVendorLoading(false);
      }
    });
    fetchQuickBooksGLAccounts(configId).then((response) => {
      if (response.data && response.data.status_code && response.data.status_code === 401) {
        console.log('Token Expired');
      }
      if (response.data.gl_list) {
        prepareGLList(response.data.gl_list);
        setIsGLLoading(false);
      } else {
        console.log('Could not fetch Vendor From QuickBooks');
      }
    });
    fetchQuickBooksItems(configId).then((response) => {
      let itemList = [];
      if (response.data && response.data.item_list) {
        itemList = prepareItemList(response.data.item_list);
      }
      setItemList(itemList);
      setIsItemloading(false);
    });
  }, []);

  const prepareLineItems = (data) => {
    const lineItemList = data
      ? data.map((item) => {
          const line = {};
          line.id = item.id;
          line.dwLineName = item.docuware_line_name;
          line.itemName = item.item_name;
          line.categoryName = item.category_name;
          line.rate = item.rate ? item.rate : 0;
          line.quantity = item.quantity ? item.quantity : item.rate ? 1 : 0;
          if(item.amount && (!line.rate || line.rate === 0 ) && (!line.quantity || line.quantity === 0)){
            line.quantity = 1;
            line.rate = item.amount;
          }
          line.amount = line.rate * (line.quantity ? line.quantity : line.rate ? 1 : 0);
          line.description = item.description;
          line.hasItem = item.item_name && item.item_name !== 'False';
          line.hasCategory = item.category_name && item.category_name !== 'False';
          line.item =
            item.item_name && item.item_name !== 'False' ? { id: item.item_name, label: item.item_name } : false;
          line.category =
            item.category_name && item.category_name !== 'False'
              ? { id: item.category_name, label: item.category_name }
              : false;
          line.isShowCategory = true;
          line.isShowItem = true;
          return line;
        })
      : [];
    return lineItemList;
  };

  const configFormSchema = Yup.object().shape({
    // vendorName : Yup.string().required('Vendor Name is required')
    // accPay : Yup.string().required('Account Pay is required'),
    // billNo : Yup.string().required('Bill Number is required'),
    // billDate : Yup.string().required('Bill Date is required'),
    // categoryName : Yup.string().required('Category Name is required'),
    // amount : Yup.string().required('Amount is required')
  });
  const camelToSnake = (str) => str.replace(/([a-z])([A-Z]+)/g, (match, p1, p2) => `${p1}_${p2.toLowerCase()}`);

  const getDirtyValues = (values, initialObject) => {
    const data = { ...values };
    const keyValues = Object.keys(data);

    const dirtyValues = keyValues.filter((keyValue) => data[keyValue] !== initialObject[camelToSnake(keyValue)]);

    keyValues.forEach((key) => {
      if (!dirtyValues.includes(key)) delete data[key];
    });
    return data;
  };

  const saveDetails = (formValues) => {
    let allLineItemsAmount = 0;
    lineItems.forEach((i) => {
      allLineItemsAmount += Number(i.rate) * Number(i.quantity);
    });
    const updateData = getDirtyValues({ ...formValues, amount: allLineItemsAmount }, oldApiData);
    const updatedValue = {};
    Object.keys(updateData).forEach((i) => {
      const key = topMapping[i];
      const value = updateData[i];
      updatedValue[key] = value;
    });
    const docuQbConfigId = localStorage.getItem('configId');
    const docuwareVendorName = formValues.dwVendorName;

    const billsAmount = Number(formValues.amount) >= 0 ? Number(formValues.amount) : allLineItemsAmount;

    const qbVendorName = selectedVendor.id;

    if (Number(allLineItemsAmount) !== Number(billsAmount)) {
      alert('The total amount from the line items does not match the amount of the bill.');
    } else {
      if (selectedVendor) {
        createVendorMapping({
          docuware_vendor_name: docuwareVendorName,
          qb_vendor_name: qbVendorName,
          docu_qb_config: docuQbConfigId,
          created_by: localStorage.getItem('userId'),
          updated_by: localStorage.getItem('userId'),
        }).then((res) => {
          console.log(res);
        });
      }
      if (isLineChanged && Number(allLineItemsAmount) === Number(billsAmount)) {
        updateBillLines({ lines: lineItems, bill_id: billId }).then((res) => {
          console.log(res);
        });
      }
      updateBillDetails({ ...updatedValue, vendor_name: qbVendorName, bill_id: billId }).then((res) => {
        console.log(res);
      });
      navigate('/app/sync-bill', { replace: false });
    }
    return true;
  };

  const formik = useFormik({
    initialValues: {
      accPay: '',
      billNo: '',
      vendorName: '',
      dwVendorName: '',
      terms: '',
      billDate: '',
      dueDate: '',
      categoryName: '',
      itemName: '',
      customerName: '',
      description: '',
      quantity: '',
      rate: '',
      amount: '',
      locationName: '',
      className: '',
      privateNote: '',
      memo: '',
      line_items: [],
    },
    validationSchema: configFormSchema,
    onSubmit: () => {
      const isSaved = saveDetails(formik.values);
      console.log(' -- isSaved ', isSaved);
    },
  });

  const { handleSubmit, getFieldProps, handleChange, setFieldValue } = formik;

  useEffect(() => {
    if (isLineAmountChanged) {
      let count = 0;
      lineItems.forEach((i) => {
        handleLineTotalAmountChange();
        count += Number(i.rate) * Number(i.quantity);
      });
      setIsLineAmountChanged(false);
      setFieldValue('amount', count.toFixed(2));
    }
  }, [isLineAmountChanged]);

  const fetchRowValue = (rowId, updatedList) => {
    const foundRow = updatedList.find((row) => row.id === rowId);
    return foundRow || false;
  };

  const handleLineTotalAmountChange = () => {
    const updatedRows = (lineItems) => {
      return lineItems.map((row) => {
        return { ...row, amount: row.rate * row.quantity, isShowCategory: false };
      });
    };
    const updatedList = updatedRows(lineItems);

    setLineItems(updatedList);
  };

  const handleCellValueChange = (event, fieldName, newValue, rowId, index) => {
    const updatedRows = (lineItems) => {
      if (rowId) {
        return lineItems.map((row) =>
          row.id === rowId ? { ...row, [fieldName]: newValue, isShowCategory: false } : row
        );
      }
      return lineItems.map((row, rowIndex) =>
        rowIndex === index ? { ...row, [fieldName]: newValue, isShowCategory: false } : row
      );
    };

    const updatedList = updatedRows(lineItems);

    setLineItems(updatedList);
  };

  const validateRow = (row, rowId) => {
    if (!row.category && !row.item) {
      alert('Either Item or GL is allowed. Please select any one of them.');
    } else if (row.category && row.item) {
      const updatedRows = (lineItems) =>
        lineItems.map((lineRow) =>
          lineRow.id === rowId
            ? { ...lineRow, category: false, item: false, isShowItem: true, isShowCategory: true }
            : row
        );
      const updatedList = updatedRows(lineItems);
      setLineItems(updatedList);
      alert('Either Item or GL is allowed. Please select any one of them.');
    }
  };

  const setFormInitialValues = (apiData) => {
    if (apiData.vendor_name && apiData.vendor_name !== 'False') {
      setSelectedVendor({ id: apiData.vendor_name, label: apiData.vendor_name });
    }
    if (!apiData.is_imported_in_qb) {
      setIsShowEdit(true);
    }
    formik.setValues({
      accPay: apiData.acc_pay,
      billNo: apiData.bill_no,
      vendorName: apiData.vendor_name,
      dwVendorName: apiData.docuware_vendor_name,
      isImportedInQB: apiData.is_imported_in_qb,
      terms: apiData.terms,
      billDate: apiData.bill_date,
      dueDate: apiData.due_date,
      categoryName: apiData.category_name,
      itemName: apiData.item_name,
      customerName: apiData.customer_name,
      description: apiData.description,
      quantity: apiData.quantity,
      rate: apiData.rate,
      amount: apiData.amount,
      locationName: apiData.location_name,
      className: apiData.class_name,
      privateNote: apiData.private_note,
      memo: apiData.memo,
    });
  };

  const navigates = [];
  if (isShowEdit) {
    navigates.push({ path: '/app/sync-bill', name: 'Transaction Sync' });
  } else {
    navigates.push({ path: '/app/transaction-history', name: 'Transaction History' });
  }
  navigates.push({ path: '', name: 'Bill Details' });

  const isSidebarOpen =
    localStorage.getItem('isSidebarOpen') === 'true' || localStorage.getItem('isSidebarOpen') === null;

  const handleDateChange = (e, name) => {
    setFieldValue(name, dayjs(e).format('YYYY-MM-DD'));
  };

  const handleCreateVendor = () => {
    const configId = localStorage.getItem('configId');
    setOpenModal(false);
    createQuickbooksVendor({ docu_qb_config_id: configId, vendor_name: selectedVendor.id }).then((response) => {
      // TODO : Display Message here
      console.log(response);
      if (response.status === 200) {
        fetchQuickBooksVendors(configId).then((response) => {
          if (response.data && response.data.status_code && response.data.status_code === 401) {
            console.log('Token Expired');
          }
          if (response.data.vendor_list) {
            prepareQBVendorList(response.data.vendor_list);
            setIsQBVendorLoading(false);
          } else {
            console.log('Could not fetch Vendor From QuickBooks');
            setIsQBVendorLoading(false);
          }
        });
      }
    });
  };

  const handleClickIcon = (value) => {
    setSelectedDWVendor({ id: value, label: value });
    setOpenModal(true);
  };

  const handleCancelIcon =()=>{
    setLastUpdated(oldApiData.updated_at);
        if (oldApiData.is_imported_in_qb) {
          if (oldApiData.is_bill_paid) {
            setBillStatus('Paid');
            return;
          }
          setBillStatus('Synced');
        } else {
          setBillStatus('Not Synced');
        }
        const cacheFileCabinet = localStorage.getItem('cacheConfigFileCabinetId')
          ? localStorage.getItem('cacheConfigFileCabinetId')
          : false;
        setFileCabinet(cacheFileCabinet);
        setDwDocId(oldApiData.docuware_document_id);
        setFormInitialValues(oldApiData);
        if (oldApiData.vendor_name === (undefined || null) || oldApiData.vendor_name === 'False') {
          setIsShowQbVendor(true);
        }
        const lineItemsApiData = oldApiData.docuware_bill_line || [];
        if (lineItemsApiData) {
          const preparedLines = prepareLineItems(lineItemsApiData);
          setLineItems(preparedLines);
        }
  }
  return (
    <Page title="Bill Detail" style={{ marginLeft: isSidebarOpen ? (isEdit ? 90 : 15 ) : 0 }}>
      <Card variant="outlined" sx={{ mt: 0 }} style={{ overflow: 'visible', borderRadius: 5 }}>
        {isBillDetailsLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress style={{ margin: 10 }} />
          </div>
        ) : (
          <>
            <Breadcrumbs separator=">" navigates={navigates} />
            <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" sx={{ mb: 10, mt: 3 }}>
              <Container style={{ maxWidth: '100%' }}>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Box sx={{ textAlign: 'left', display: 'flex' }}>
                      <Typography variant="h5" style={{ marginTop: 5 }}>
                        Bill Info
                      </Typography>
                      {!isEdit ? (
                        <Button
                          style={{ marginLeft: 15 }}
                          size="small"
                          color="inherit"
                          variant="outlined"
                          sx={{ borderColor: 'grey' }}
                          onClick={() => setIsEdit(true)}
                        >
                          <Icon style={{ color: '#2E3192', height: '20px', width: '20px' }} icon="carbon:edit" />
                          <span style={{ fontSize: 15, marginLeft: 5 }}>Edit</span>
                        </Button>
                      ) : (
                        <Button
                          style={{ marginLeft: 15 }}
                          size="small"
                          color="inherit"
                          variant="outlined"
                          sx={{ borderColor: 'grey' }}

                            onClick={() => {
                              handleCancelIcon()
                              setIsEdit(false)}}
                        >
                          <Icon
                            style={{ color: 'red', height: '20px', width: '20px' }}
                            icon="material-symbols:cancel-outline"
                          />
                          <span style={{ fontSize: 15, marginLeft: 5 }}>Cancel</span>
                        </Button>
                      )}
                      {isEdit && (
                        <Button
                          style={{ marginLeft: 15 }}
                          size="small"
                          color="inherit"
                          variant="outlined"
                          type="submit"
                          sx={{ borderColor: 'grey' }}
                        >
                          <Icon style={{ color: '#2E3192', height: '20px', width: '20px' }} icon="ion:save-sharp" />
                          <span style={{ fontSize: 15, marginLeft: 5 }}>Save</span>
                        </Button>
                      )}
                      <div style={{ position: 'absolute', right: 30, display: 'grid' }}>
                        <div style={{ marginLeft: -10 }}>
                          <Badge
                            badgeContent={billStatus}
                            style={{ width: 100 }}
                            color={billStatus === 'Synced' ? 'info' : billStatus === 'Paid' ? 'Success' : 'warning'}
                          />
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <Iconify icon="arcticons:wisetimer" />
                          </div>
                          <div style={{ fontSize: 10, marginTop: 4, marginLeft: 10 }}>
                            {dayjs(lastUpdated).format('MM-DD-YYYY LT')}
                          </div>
                        </div>
                      </div>
                    </Box>
                    <Stack spacing={2} style={{ marginTop: 15 }}>
                      <Stack direction="row" spacing={2}>
                        <Box sx={{ flex: 1, padding: 2, width: 650 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={5}>
                              Bill Number
                            </Grid>
                            <Grid item xs={7}>
                              <TextField
                                fullWidth
                                autoComplete="billNo"
                                size="small"
                                disabled={!isEdit}
                                type="text"
                                {...getFieldProps('billNo')}
                                onChange={handleChange}
                                label="Bill Number"
                              />
                            </Grid>
                            <Grid item xs={5}>
                              Docuware Vendor Name
                            </Grid>
                            <Grid item xs={7} sx={{ display: 'flex' }}>
                              <TextField
                                fullWidth
                                autoComplete="dwVendorName"
                                size="small"
                                disabled={!isEdit}
                                type="text"
                                {...getFieldProps('dwVendorName')}
                                onChange={handleChange}
                                label="DW Vendor Name"
                              />
                              <Tooltip title="Create Vendor" placement="top" arrow>
                                <IconButton
                                  size="large"
                                  color="inherit"
                                  disabled={!isEdit}
                                  onClick={() => handleClickIcon(getFieldProps('dwVendorName').value || '')}
                                >
                                  <Icon
                                    icon="mingcute:user-add-2-line"
                                    style={{ color: isEdit ? 'green' : 'grey', height: '20px', width: '20px' }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={5}>
                              Vendor Name
                            </Grid>
                            <Grid item xs={7}>
                              {isEdit ? (
                                <Autocomplete
                                  loading={isQBVendorLoading}
                                  id="combo-box-demo"
                                  options={vendorList}
                                  disabled={!isEdit}
                                  size={'small'}
                                  value={selectedVendor ? selectedVendor.label : null}
                                  defaultValue={null}
                                  onChange={(event, newValue) => {
                                    setSelectedVendor(newValue);
                                  }}
                                  isOptionEqualToValue={(option, value) => option.id === value.id}
                                  renderInput={(params) => <TextField {...params} label={'Select QB Vendor'} />}
                                />
                              ) : (
                                <TextField
                                  fullWidth
                                  autoComplete="vendorName"
                                  size="small"
                                  disabled={!isEdit}
                                  type="text"
                                  {...getFieldProps('vendorName')}
                                  onChange={(event) => {
                                    setConfigName(event.target.value);
                                  }}
                                  label={'QB Vendor Name'}
                                />
                              )}
                            </Grid>
                            <Grid item xs={5}>
                              Terms
                            </Grid>
                            <Grid item xs={7}>
                              <TextField
                                fullWidth
                                autoComplete="terms"
                                size="small"
                                disabled={!isEdit}
                                type="text"
                                {...getFieldProps('terms')}
                                onChange={handleChange}
                                label={'Terms'}
                              />
                            </Grid>
                            <Grid item xs={5}>
                              Bill Date *
                            </Grid>
                            <Grid item xs={7}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  disabled={!isEdit}
                                  label="Bill Date"
                                  value={dayjs(getFieldProps('billDate').value)}
                                  onChange={(e) => handleDateChange(e, 'billDate')}
                                  sx={{
                                    width: '100%',
                                    '& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
                                      height: '6px',
                                    },
                                    '& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input': {
                                      height: '6px',
                                    },
                                    '& .css-1nuss9t': {
                                      height: '6px',
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={5}>
                              Due Date
                            </Grid>
                            <Grid item xs={7}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  disabled={!isEdit}
                                  label="Due Date"
                                  value={dayjs(getFieldProps('dueDate').value)}
                                  onChange={(e) => handleDateChange(e, 'dueDate')}
                                  sx={{
                                    width: '100%',
                                    '& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
                                      height: '6px',
                                    },
                                    '& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input': {
                                      height: '6px',
                                    },
                                    '& .css-1nuss9t': {
                                      height: '6px',
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={5}>
                              Amount *
                            </Grid>
                            <Grid item xs={7}>
                              <TextField
                                fullWidth
                                autoComplete="amount"
                                size="small"
                                disabled={!isEdit}
                                type="number"
                                {...getFieldProps('amount')}
                                onChange={handleChange}
                                label={getFieldProps('amount').value ? '' : 'Amount'}
                              />
                            </Grid>
                            <Grid item xs={5}>
                              Location Name
                            </Grid>
                            <Grid item xs={7}>
                              <TextField
                                fullWidth
                                autoComplete="locationName"
                                size="small"
                                disabled={!isEdit}
                                type="text"
                                {...getFieldProps('locationName')}
                                onChange={handleChange}
                                label={'Location Name'}
                              />
                            </Grid>
                            <Grid item xs={5}>
                              Private Note
                            </Grid>
                            <Grid item xs={7}>
                              <TextField
                                fullWidth
                                autoComplete="privateNote"
                                size="small"
                                disabled={!isEdit}
                                type="text"
                                {...getFieldProps('privateNote')}
                                onChange={handleChange}
                                label={'Private Note'}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box sx={{ flex: 1, padding: 2, border: '1px solid' }}>
                          <Button
                            variant="outlined"
                            color="primary"
                            sx={{ marginBottom: 2 }}
                            onClick={() => window.open(qbBillLink, '_blank')}
                          >
                            Expand
                          </Button>
                          <iframe
                            width="100%"
                            height="100%"
                            src={qbBillLink}
                            title="qbBillLink"
                            style={{
                              border: 'none',
                              minHeight: '92%', // Adjust height as needed
                              height: 'calc(100vh - 450px)', // Optional: adjusts height to be responsive to the screen size
                              width: '100%',
                            }}
                          />
                        </Box>
                      </Stack>

                      <Box sx={{ width: '100%', padding: 2 }}>
                        <Grid item xs={5} style={{ display: 'flex' }}>
                          <div>
                            <b>Line Items</b>
                          </div>
                          {isEdit && (
                            <div style={{ position: 'absolute', right: 30 }}>
                              <Button
                                size="small"
                                color="inherit"
                                variant="outlined"
                                sx={{ borderColor: 'grey' }}
                                onClick={() => setLineItems([...lineItems, lineMapping])}
                              >
                                Add Line Item
                              </Button>
                            </div>
                          )}
                        </Grid>

                        <br />
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 700 }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">Dw Line</TableCell>
                                <TableCell align="left">QB Item</TableCell>
                                <TableCell align="left">GL</TableCell>
                                <TableCell align="right">Rate</TableCell>
                                <TableCell align="right">Quantity</TableCell>
                                <TableCell align="right">Total Amount</TableCell>
                                <TableCell align="left">Description</TableCell>
                                <TableCell align="right" />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {lineItems.map((row, index) => (
                                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                  <TableCell style={{ paddingTop: 10, paddingBottom: 5 }} align="left">
                                    {!isEdit ? (
                                      row.dwLineName && row.dwLineName !== 'False' ? (
                                        row.dwLineName
                                      ) : (
                                        '-'
                                      )
                                    ) : (
                                      <TextField
                                        fullWidth
                                        autoComplete="dwLineName"
                                        size="small"
                                        disabled={!isEdit}
                                        type="text"
                                        value={row.dwLineName}
                                        onChange={(event, newValue) => {
                                          handleCellValueChange(event, 'dwLineName', newValue, row.id, index);
                                          setIsLineChanged(true);
                                        }}
                                        label={'Line Name'}
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell style={{ paddingTop: 10, paddingBottom: 5, width: '22%' }} align="left">
                                    {(!row.hasCategory && !row.hasItem) || isEdit ? (
                                      <Autocomplete
                                        loading={isItemLoading}
                                        id="combo-box-demo"
                                        options={itemList}
                                        disabled={!isEdit}
                                        size={'small'}
                                        value={row.item || null}
                                        defaultValue={null}
                                        onChange={(event, newValue) => {
                                          handleCellValueChange(event, 'item', newValue, row.id, index);
                                          setIsLineChanged(true);
                                        }}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => (
                                          <TextField {...params} label={row.item ? '' : 'Select Item'} />
                                        )}
                                        renderOption={(props, option) => {
                                          return (
                                            <li {...props} key={index}>
                                              {option.label}
                                            </li>
                                          );
                                        }}
                                      />
                                    ) : row.itemName && row.itemName !== 'False' ? (
                                      row.itemName
                                    ) : (
                                      '-'
                                    )}
                                  </TableCell>
                                  <TableCell style={{ paddingTop: 10, paddingBottom: 5, width: '22%' }} align="left">
                                    {(!row.hasCategory && !row.hasItem) || isEdit ? (
                                      <Autocomplete
                                        loading={isGlLoading}
                                        id="combo-box-demo"
                                        options={glList}
                                        disabled={!isEdit}
                                        size={'small'}
                                        value={row.category || null}
                                        defaultValue={null}
                                        onChange={(event, newValue) => {
                                          handleCellValueChange(event, 'category', newValue, row.id, index);
                                          setIsLineChanged(true);
                                        }}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => (
                                          <TextField {...params} label={row.category ? '' : 'Select GL Account'} />
                                        )}
                                        renderOption={(props, option) => {
                                          return (
                                            <li {...props} key={index}>
                                              {option.label}
                                            </li>
                                          );
                                        }}
                                      />
                                    ) : row.categoryName && row.categoryName !== 'False' ? (
                                      row.categoryName
                                    ) : (
                                      '-'
                                    )}
                                  </TableCell>
                                  <TableCell style={{ paddingTop: 10, paddingBottom: 5 }} align="right">
                                    {!isEdit ? (
                                      row.rate && row.rate !== 'False' ? (
                                        row.rate
                                      ) : (
                                        '-'
                                      )
                                    ) : (
                                      <TextField
                                        fullWidth
                                        autoComplete="dwLineAmount"
                                        size="small"
                                        disabled={!isEdit}
                                        type="text"
                                        value={row.rate}
                                        onChange={(event) => {
                                          handleCellValueChange(
                                            event,
                                            'rate',
                                            Number(event.target.value),
                                            row.id,
                                            index
                                          );
                                          setIsLineChanged(true);
                                          setIsLineAmountChanged(true);
                                        }}
                                        label={'Rate'}
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell style={{ paddingTop: 10, paddingBottom: 5 }} align="right">
                                    {!isEdit ? (
                                      row.quantity && row.quantity !== 'False' ? (
                                        row.quantity
                                      ) : (
                                        '-'
                                      )
                                    ) : (
                                      <TextField
                                        fullWidth
                                        autoComplete="dwLineQuantity"
                                        size="small"
                                        disabled={!isEdit}
                                        type="text"
                                        value={row.quantity}
                                        onChange={(event) => {
                                          handleCellValueChange(
                                            event,
                                            'quantity',
                                            Number(event.target.value),
                                            row.id,
                                            index
                                          );
                                          setIsLineChanged(true);
                                          setIsLineAmountChanged(true);
                                        }}
                                        label={'Quantity'}
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell style={{ paddingTop: 10, paddingBottom: 5 }} align="right">
                                    {row.amount && row.amount !== 'False' ? row.amount : '0'}
                                  </TableCell>
                                  <TableCell style={{ paddingTop: 10, paddingBottom: 5 }} align="left">
                                    {!isEdit ? (
                                      row.description && row.description !== 'False' ? (
                                        row.description
                                      ) : (
                                        '-'
                                      )
                                    ) : (
                                      <TextField
                                        fullWidth
                                        autoComplete="dwLineAmount"
                                        size="small"
                                        disabled={!isEdit}
                                        type="text"
                                        value={row.description}
                                        onChange={(event) => {
                                          handleCellValueChange(
                                            event,
                                            'description',
                                            event.target.value,
                                            row.id,
                                            index
                                          );
                                          setIsLineChanged(true);
                                        }}
                                        label={'Description'}
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell
                                    style={{ paddingTop: 10, paddingBottom: 5, paddingLeft: 0, paddingRight: 0 }}
                                    align="right"
                                  >
                                    {isEdit ? (
                                      <Button
                                        size="small"
                                        color="inherit"
                                        variant="outlined"
                                        sx={{ borderColor: 'grey' }}
                                        onClick={() => {
                                          setIsLineChanged(true);
                                          setIsLineAmountChanged(true);
                                          const items = lineItems;
                                          if (index > -1) {
                                            items.splice(index, 1);
                                          }
                                          setLineItems([...items]);
                                        }}
                                      >
                                        Remove
                                      </Button>
                                    ) : (
                                      fileCabinet &&
                                      dwDocId && (
                                        <IconButton
                                          size="large"
                                          color="inherit"
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            if (fileCabinet && dwDocId) {
                                              const dwServerUrl = DWDomain || 'https://inceptiontech.docuware.cloud';
                                              const url = `${dwServerUrl}/DocuWare/Platform/WebClient/Integration?p=V&did=${dwDocId}&fc=${fileCabinet}`;
                                              window.open(url);
                                            } else {
                                              alert('Can not find file cabinet from Configuration');
                                            }
                                          }}
                                        >
                                          <DocuwareIcon style={{ width: '24px', height: '24px' }} />
                                        </IconButton>
                                      )
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Stack>
                  </Form>
                </FormikProvider>
              </Container>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={() => setOpenModal(false)}
                closeAfterTransition
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Fade in={openModal}>
                  <Card variant="outlined" sx={{ mt: 0, width: '40%' }} style={{ borderRadius: 5 }}>
                    <Typography variant="h5" style={{ margin: 10 }}>
                      Create Vendor
                    </Typography>
                    <div style={{ display: 'flex', margin: 10, marginTop: 25, justifyContent: 'center' }}>
                      <TextField
                        label="Vendor Name"
                        required
                        fullWidth
                        size="small"
                        type="text"
                        value={selectedDWVendor.label}
                        onChange={(e) => {
                          setFieldValue('dwVendorName', e.target.value);
                          setSelectedDWVendor({ id: e.target.value, label: e.target.value });
                        }}
                      />
                    </div>
                    <div style={{ margin: 20, justifyContent: 'center' }}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        style={{
                          maxWidth: '100px',
                          minWidth: '100px',
                          maxHeight: '40px',
                          minHeight: '40px',
                          marginRight: 10,
                        }}
                        onClick={() => {
                          setSelectedVendor(selectedDWVendor);
                          handleCreateVendor();
                        }}
                        loading={false}
                        disabled={!selectedVendor}
                      >
                        Create
                      </LoadingButton>
                      <LoadingButton
                        onClick={() => setOpenModal(false)}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        style={{
                          backgroundColor: '#D43F3E',
                          maxWidth: '100px',
                          minWidth: '100px',
                          maxHeight: '40px',
                          minHeight: '40px',
                        }}
                        loading={false}
                      >
                        Cancel
                      </LoadingButton>
                    </div>
                  </Card>
                </Fade>
              </Modal>
            </Stack>
          </>
        )}
      </Card>
    </Page>
  );
}
